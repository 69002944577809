import { BookingDetails, PlaceOrder } from "../../../pages";
import adminRouteMap from "../../../routeControl/adminRouteMap";

export default function route() {
  return [
    {
      path: adminRouteMap.PLACE_ORDER.path,
      name: "Place Order",
      key: adminRouteMap.PLACE_ORDER.path,
      private: false,
      belongsToSidebar: true,
      icon: adminRouteMap.PLACE_ORDER.icon,
      element: <PlaceOrder />,
    },
    {
      path: adminRouteMap.ORDER_DETAILS.path,
      name: "Order Details",
      key: adminRouteMap.ORDER_DETAILS.path,
      private: false,
      belongsToSidebar: false,
      icon: adminRouteMap.ORDER_DETAILS.icon,
      element: <BookingDetails />,
    },
  ];
}
