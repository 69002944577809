import { Booking, BookingDetails } from "../../../pages";
import adminRouteMap from "../../../routeControl/adminRouteMap";

export default function route() {
  return [
    {
      path: adminRouteMap.BOOKING.path,
      name: "Booking",
      key: adminRouteMap.BOOKING.path,
      private: false,
      belongsToSidebar: true,
      icon: adminRouteMap.BOOKING.icon,
      element: <Booking />,
    },
    {
      path: `${adminRouteMap.BOOKING_DETAILS.path}/:id`,
      name: "Booking",
      key: `${adminRouteMap.BOOKING_DETAILS.path}/:id`,
      private: false,
      belongsToSidebar: false,
      icon: adminRouteMap.BOOKING_DETAILS.icon,
      element: <BookingDetails />,
    },
  ];
}
