import { createSlice } from "@reduxjs/toolkit";
import moduleRoutesMap from "../../routeControl";
import { removeLocalStorageToken } from "../../utils";
import logger from "../../utils/logger";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    userData: {},
    sideBarKey: "0",
    specification: undefined,
  },
  reducers: {
    loginAction: (state, action) => {
      return (state = {
        ...state,
        userData: { ...action.payload },
      });
    },
    logoutAction: (state) => {
      return (state = {
        ...state,
        userData: {},
        sideBarKey: "0",
      });
    },

    sideBarAction: (state, action) => {
      return (state = {
        ...state,
        sideBarKey: action.payload,
      });
    },

    performanceAction: (state, action) => {
      // state.specification.push(action.payload);
      // let { specification } = state;

      return {
        ...state,
        specification: Array.isArray(state.specification)
          ? [...state.specification, action.payload]
          : [action.payload],
      };

      // if (specification?.length > 0) {
      //   state.specification = specification?.map((item) =>
      //     item.id === action.payload.id ? action.payload : item
      //   );
      // } else {
      //   specification = [...state.specification, action.payload];
      // }
    },
    updatePerformanceAction: (state, action) => {
      let { specification } = state;
      state.specification = specification.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
    },
    resetPerformanceAction: (state) => {
      return (state = {
        ...state,
        specification: [],
      });
    },
  },
});

export const {
  loginAction,
  logoutAction,
  sideBarAction,
  performanceAction,
  updatePerformanceAction,
  resetPerformanceAction,
} = authSlice.actions;

export const login = (data) => async (dispatch) => {
  try {
    dispatch(loginAction(data));
  } catch (error) {
    logger(error);
  }
};

export const logout = (navigate, userRole) => async (dispatch) => {
  try {
    // if (!remember) {
    //   removeSessionStorageToken();
    // }
    removeLocalStorageToken();
    dispatch(logoutAction());
    navigate("/");
    navigate(moduleRoutesMap[userRole].LOGIN.path);
  } catch (error) {
    logger(error);
  }
};

export const updateSidebarKey = (data) => async (dispatch) => {
  try {
    dispatch(sideBarAction(data));
  } catch (error) {
    logger(error);
  }
};

export const performanceData = (data) => async (dispatch) => {
  try {
    dispatch(performanceAction(data));
  } catch (error) {
    logger(error);
  }
};

export const updatePerformanceData = (data) => async (dispatch) => {
  try {
    dispatch(updatePerformanceAction(data));
  } catch (error) {
    logger(error);
  }
};
export const resetPerformanceData = (data) => async (dispatch) => {
  try {
    dispatch(resetPerformanceAction(data));
  } catch (error) {
    logger(error);
  }
};

export const selectUserData = (state) => state.auth.userData;
export const getSidebarKey = (state) => state.auth.sideBarKey;
export const getDeviceData = (state) => state.auth.specification;

export default authSlice.reducer;
